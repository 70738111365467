import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"
import "./paper-entry.scss"

const PaperEntry = ({ paper, showAuthors = true }) => {
  const content = (
    <div className="paper">
      <div>
        <div className="title">{paper.title}</div>
        {showAuthors && <div className="authors">{paper.authors.join(", ")}</div>}
        <div className="publication">{paper.publication}</div>
      </div>
      {paper.thumbnail === null ? null : (
        <div className="image">
          <Img fluid={paper.thumbnail.childImageSharp.fluid} />
        </div>
      )}
    </div>
  )
  return (
    <Link className="undecorated-link" to={paper.stub ? "#" : "/" + paper.slug}>
      {content}
    </Link>
  )
}

PaperEntry.propTypes = {
  paper: PropTypes.object.isRequired,
  showAuthors: PropTypes.bool,
}

export default PaperEntry
