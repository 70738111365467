import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import { groupBy } from "lodash"
import PaperEntry from "../components/paper-entry"
import PropTypes from "prop-types"
import "./publications.scss"

const PaperOfYear = ({ group }) => (
  <>
    <h2 id={group.slug}>{group.year}</h2>
    <div className="publications__paper-list">
      {group.papers.map((paper) => (
        <PaperEntry key={paper.id} paper={paper} />
      ))}
    </div>
  </>
)

PaperOfYear.propTypes = {
  group: PropTypes.object.isRequired,
}

function groupByYear(papers) {
  const before2017 = papers.filter((x) => x.year < 2017)
  const after2017 = papers.filter((x) => x.year > 2016)
  const groups = Object.entries(groupBy(after2017, (x) => x.year)).map((x) => {
    return {
      year: x[0].toString(),
      slug: x[0].toString(),
      papers: x[1].sort((m, n) => m.title.localeCompare(n.title)),
    }
  })
  groups.sort((x, y) => y.year.localeCompare(x.year))
  console.log(groups)
  groups.forEach((g) => g.papers.sort((a, b) => (b.uploadMonth ?? 0) - (a.uploadMonth ?? 0)))
  // Manually add a group of papers before 2017
  groups.push({
    year: "Before 2017",
    slug: "before-2017",
    papers: before2017,
  })
  return groups
}

const PublicationPage = ({ data }) => {
  const papers = data.allMarkdownRemark.edges.map((x) => ({
    ...x.node.frontmatter,
    id: x.node.id,
    slug: x.node.fields.slug,
  }))
  const groups = groupByYear(papers)
  return (
    <Layout className="publications__container">
      <SEO title="Publications" />
      <aside>
        <section>
          <div className="section-title">Navigate by Year</div>
          <ul>
            {groups.map(({ year, slug, papers }) => (
              <li key={slug}>
                <a href={`#${slug}`}>
                  {year}&nbsp;({papers.length})
                </a>
              </li>
            ))}
          </ul>
        </section>
      </aside>
      <main className="entry-list">
        {groups.map((group) => (
          <PaperOfYear key={group.slug} group={group} />
        ))}
      </main>
    </Layout>
  )
}

PublicationPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default PublicationPage

export const pageQuery = graphql`
  query PaperPageQuery {
    allMarkdownRemark(
      sort: { fields: [frontmatter___year, frontmatter___title], order: DESC }
      filter: { fields: { sourceInstanceName: { eq: "publications" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            authors
            video
            publication
            originalPost
            stub
            year
            uploadMonth
            thumbnail {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            feature
          }
        }
      }
    }
  }
`
